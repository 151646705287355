import react from 'react';
import {NavLink} from 'react-router-dom'
// import PrivacyPolicy from './Components/Main/PrivacyPolicy'



function Footer() {
  return (
    <react.Fragment>

      {/* Footer Top Area Start */}
      <div id="footer-top-area" className="footer-top-area bg-dark section pt-100 pb-50">
      <div className="container">
      <div className="row">
    {/*  Footer Widget  */}
      <div className="footer-widget col-lg-4 col-md-6 col-12 mb-50">
    {/*  About Widget  */}
      <div className="about-widget">
      <img src="/assets/img/logo_white.png" alt="" />
      <p>Survey no. 873/B/3, ANSONS Limbani Estate, Road No.1, Near GETCO 66 K.V Substation, Por, Vadodara, Gujarat, 391243</p>
    {/* Footer Social */}
      <div className="footer-social fix">
      <a href="#"><i className="fa fa-facebook" /></a>
      <a href="#"><i className="fa fa-rss" /></a>
      <a href="#"><i className="fa fa-google-plus" /></a>
      <a href="#"><i className="fa fa-pinterest-p" /></a>
      <a href="#"><i className="fa fa-instagram" /></a>
      </div>
      {/* <a href="#" className="button">Donate now</a> */}
      </div>
      </div>
    {/*  Footer Widget  */}
    {/* <div class="footer-widget col-lg-4 col-md-6 col-12 mb-50">
                        <h3>Latest News</h3>
                         Latest News Widget 
                        <div class="footer-news">
                            <a href="#" class="image float-left"><img src="img/blog/footer/1.jpg" alt=""></a>
                            <div class="content fix">
                                <a href="#">Finibus Bonorum Malorum</a>
                                <p>Contrary to popular belief, Lorm Ipsum is not simply random</p>
                            </div>
                        </div>
                        <div class="footer-news">
                            <a href="#" class="image float-left"><img src="img/blog/footer/2.jpg" alt=""></a>
                            <div class="content fix">
                                <a href="#">The Standard lorem ipsum</a>
                                <p>Contrary to popular belief, Lorm Ipsum is not simply random</p>
                            </div>
                        </div>
                    </div> */}
      <div className="footer-widget col-lg-4 col-md-6 col-12 mb-50">
      <h3>Usefull Link</h3>
      <div className="footer-news">
      <ul className="footer-link">
      <li><NavLink to="/">Home</NavLink></li>
      <li> <NavLink to="/product">Product</NavLink></li>
      <li> <NavLink to="/gallery">Gallery</NavLink></li>
      <li> <NavLink to="/about">About</NavLink></li>
      <li> <NavLink to="/contact">Contact</NavLink></li>
      <li> <NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
      <li> <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink></li>

      </ul>
      </div>
      </div>
    {/*  Footer Widget  */}
      <div className="footer-widget col-lg-4 col-md-6 col-12 mb-50">
      <h3>Granules Photos</h3>
    {/*  Flickr Widget  */}
      <ul className="flickr-gallery">
      
      <li><img src="assets/img/footer/footer1.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer2.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer3.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer4.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer5.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer6.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer7.jpg" alt="" /></li>
      <li><img src="assets/img/footer/footer8.jpg" alt="" /></li>
     
      </ul>
      </div>
      </div>
      </div>
      </div>
    {/* Footer Top Area End */}
    {/* Footer Bottom Area Start */}
      <div id="footer-bottom-area" className="footer-bottom-area section">
    {/* Footer Top Area End */}
      <div className="container">
      <div className="row justify-content-between">
    {/* Copyright */}
      <div className="copyright col-md-auto col-12">
      <p>Copyright © <span>Crystal Polymers</span> 2022. All right reserved</p>
      </div>
    {/* Author Credit */}
      <div className="author-credit col-md-auto col-12">
      <p>Created by <a href="https://limbanisoftwares.com/" target="_blank">Limbani Softwares</a> With <i className="fa fa-heart" /></p>
      </div>
      </div>
      </div>
      </div>
    {/* Footer Bottom Area End */}

    </react.Fragment>
  );
}

export default Footer;