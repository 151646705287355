import React from 'react'
import { NavLink } from 'react-router-dom'


export default function TermsAndConditions() {
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>TERMS AND CONDITIONS</h2>
                            <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li> <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="contact-area" class="contact-area section pt-120 pb-70">
                <div class="container">
                <p>**Crystal Polymers:  Terms and Conditions**</p>

    <p>
        **1. Ordering and Minimum Quantity (MOQ): **
        <ul>
            <li>
            - All orders are subject to acceptance by Crystal Polymers.
            </li>
            <li>
            - The minimum order quantity (MOQ) = 5MT.
            </li>
        </ul>
   
    </p>
    <p>
        **2. Pricing and Payment:**
        <ul>
            <li>
            - Prices are quoted in [currency] and are subject to change without notice.            
            </li>
            <li>
            - Payment terms are [specify terms], and all payments must be made in [currency].
            </li>
            <li>
            - Late payments may be subject to [specify late payment fees or penalties].

            </li>
        </ul>
   
    </p>
    <p>
        **3. Production and Lead Times:**
        <ul>
            <li>
            - Production lead times will be communicated upon order confirmation.
            </li>
            <li>
            - Crystal Polymers will not be responsible for delays caused by factors beyond our control, such as raw material shortages, machine breakdowns, or force majeure events.
            </li>
            
        </ul>
   
    </p>

    <p>
    **4. Quality Standards:**
    <ul>
        <li>Crystal Polymers adheres to industry-standard quality control measures.</li>
        <li>Defective products must be reported within [specify timeframe] of receipt, and Crystal Polymers will replace or refund accordingly.</li>
    </ul>
</p>

<p>
    **5. Changes and Cancellations:**
    <ul>
        <li>Changes to orders must be requested in writing and are subject to approval.</li>
        <li>Cancellations are accepted within [specify timeframe], but may be subject to cancellation fees.</li>
    </ul>
</p>

<p>
    **6. Shipping and Delivery:**
    <ul>
        <li>Shipping costs are the responsibility of the customer unless otherwise specified.</li>
        <li>Delivery dates are estimates, and Crystal Polymers is not liable for delays in transit.</li>
    </ul>
</p>

<p>
    **7. Intellectual Property:**
    <ul>
        <li>Customers must ensure that they have the legal right to produce items using provided molds or designs.</li>
        <li>Crystal Polymers retains the intellectual property rights for any molds or designs created for the customer.</li>
    </ul>
</p>

<p>
    **8. Confidentiality:**
    <ul>
        <li>Both parties agree to keep any confidential information exchanged during the course of the business relationship confidential.</li>
    </ul>
</p>

<p>
    **9. Governing Law:**
    <ul>
        <li>These terms and conditions are governed by the laws of [specify jurisdiction], and any disputes will be resolved through arbitration or litigation as per the laws of the jurisdiction.</li>
    </ul>
</p>

<p>
    **10. Miscellaneous:**
    <ul>
        <li>[Any other important terms or conditions specific to your business.]</li>
    </ul>
</p>






<p>By engaging in business with Crystal Polymers, the customer agrees to these terms and conditions.</p>
<p></p>
<p>
---
</p>
<p></p>
<p>
Remember to customize this template to fit your company's specific needs and consult with legal professionals to ensure compliance with relevant laws and regulations.
</p>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </div>
    )
}
