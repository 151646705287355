import React from 'react'
import { NavLink } from 'react-router-dom'


export default function PrivacyPolicy() {
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>Privacy Policy</h2>
                            <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li> <NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="contact-area" class="contact-area section pt-120 pb-70">
            <div class="container"> 
              <p>CRYSTAL.BUYER – The Polymer Distribution Company</p>
    <p>We, CRYSTAL POLYMERS (“CRYSTAL POLYMERS,” “Company,” “we,” “us,” “our”), are the owners of the website <a href="https://www.CRYSTALPOLYMERS.COM">www.CRYSTALPOLYMERS.COM</a> (the “Website”) and a mobile-based application named CRYSTAL.BUYER (“App”), collectively referred to as the platform (the “Platform”). This Platform showcases our products and services offerings and facilitates the buying, selling, and distribution of polymers/commodities all across India (“Services”).</p>
    
    
    <p>We respect data privacy rights and are committed to protecting personal information collected on the Platform. This privacy policy (“Privacy Policy”) sets forth how we collect, use, and protect the personal information collected on the Platform.</p>
    <p>PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CLICKING THE CHECKBOX OR BY CONTINUING TO USE THE PLATFORM, PROVIDING US PERSONAL INFORMATION, YOU CONSENT TO OUR USE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU MAY WITHDRAW YOUR CONSENT OR ALTERNATIVELY CHOOSE NOT TO PROVIDE YOUR PERSONAL INFORMATION ON THE PLATFORM.</p>
      <p>IF YOU ARE ACCESSING THE WEBSITE ON BEHALF OF A THIRD PARTY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH THIRD-PARTY TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY. IF YOU DO NOT HAVE SUCH AUTHORITY (TO PROVIDE ANY PERSONAL INFORMATION OF A THIRD PARTY) OR DO NOT AGREE TO THE TERMS OF THIS PRIVACY POLICY, THEN YOU SHOULD REFRAIN FROM USING THE PLATFORM OR AVAILING THE SERVICES.</p>
      <p>This Privacy Policy is governed by and is compliant with The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, which is designed to protect personal information; and other applicable rules and regulations related to privacy.</p>


    <p>Definitions</p>
    <ul>
        <li>- “User/s,” “you,” “your” shall mean and include individuals who visit, access and/or use the Application;</li>
        <li>- “Personal Information” means information that can be used to identify the User, including but not limited to an individual’s full name, mobile number, residential address, email address, company name, employee number, and designation.</li>
    </ul>
    
    <p>Personal Information Collected:</p>
    <p>We may collect Personal Information in the following ways:</p>
    <ol>
        <li>Personal Information collected through the Website:
          <p>When you access, visit, use our website, fill the enquiry form, register on our website, contact us through the Website, you may provide us your Personal Information, which may include but is not limited to the first and last name, email address, permanent address, contact information, company name, office address, and designation.
            </p> 
            </li>
        <li>Personal Information collected through the App:
          <p>When you access, visit, use, or register on our App, you may provide us your Personal Information, which may include but is not limited to the first and last name, email address, permanent address, contact information, company name, office address, and designation.</p>
        </li>
        <li>Personal Information collected for the purpose of recruitment:
          <p>
          When you apply for a job/career at our Company, you may provide us Personal Information, which may include but not be limited to your first and last name, email address, permanent address, contact information, educational qualification, and previous employment information.
          </p>
        </li>
        <li>Social media platforms:
          <p>
          We may also collect, store, and use your Personal Information when you access social media pages operated by us on Facebook, YouTube, Instagram, Twitter, and/or LinkedIn.
          </p>
        </li>
    </ol>
    
    <p>Cookies</p>
    <p>What are Cookies?</p>
    <p>Cookies are alphanumeric files placed on your device when you visit the web-based admin interface of the Application.</p>
    <p>Types of Cookies Used</p>
    <ul>
        <li>- Strictly necessary cookies:
          <p>
          These cookies are essential for the operation of the Application enabling a user to effectively move around and use the Application’s features, such as remembering the expiry date of the user access information. Without strictly necessary cookies, certain features in our application may not be made possible, and our application may not perform as smoothly as it should.
          </p>
        </li>
        <li>- Permanent cookies:
          <p>
          Permanent cookies help us recognize you as an existing user, making it easier for you to return to the Application without signing in again. After signing in, the permanent cookies stay on your browser and will be read when you return to our sites. These remain on your computer/device for a pre-defined period.
          </p>
        </li>
        <li>- Session cookies:
          <p>
          Session cookies only last for as long as the session exists (they get erased when the user closes the browser).
          </p>
        </li>
        <p>
        As for the domain to which it belongs, there are either:
        </p>
        <li>- First-party cookies:
          <p>
          These cookies are set by the web server of the visited page and share the same domain.
          </p>
        </li>
        <li>- Third-party cookies:
          <p>
          These cookies are placed by a third party on your device and may provide information to us and third parties about your usage of the Application.
          </p>
        </li>
    </ul>
    
    <p>Use of Cookies:</p>
    <p>
    We use Personal Information captured through cookies to make your interaction with us faster and more secure. They may be used for the following purposes:
    </p>
    <ul>
        <li>- Prefrences:
          <p>
          Cookies allow the Application to remember information that changes the way the site behaves or looks, such

            <p>as your preferred language or the region, you are in. Remembering your preferences enables us to personalize and display advertisements and other content for you.
            </p>
          </p>
        </li>
        <li>- Security/Optimization:
          <p>
          Cookies allow us to maintain security by authenticating users, preventing fraudulent use of login credentials, and protecting user data from unauthorized parties. We may use certain types of cookies that allow us to block many types of attacks, such as attempts to steal content from the forms present on the Application.
          </p>
        </li>
        <li>- Communication:
          <p>
          We may use information collected via cookies to communicate with you, for sending newsletters, seeking your opinion and feedback, and providing you services and promotional materials.
          </p>
        </li>
        <li>- Analytics and Research:
          <p>
          We may use cookies to better understand how people use our Platform so that we can improve them.
          </p>
        </li>
        
    </ul>
    
    <p>Disabling Cookies</p>
    <p>You can decide whether to accept or not to accept cookies. You can disable cookies by adjusting the settings on your browser (please check your browser’s ‘Help’ option). Disabling cookies may affect the functionality of our application.</p>
    
    <p>
Accuracy of Information:
</p>
    <p>The User undertakes that they shall be solely responsible for the accuracy, correctness, or truthfulness of the Personal Information shared with us whether of its own or any third party. In the event the User is sharing any Personal Information on behalf of a third person, the User represents and warrants that he has the necessary authority to share such Personal Information with the Company, obtained written consent from such third party, and the Company shall not be responsible for verifying the same. The User understands and acknowledges that such Personal Information shall be subject to the terms and conditions of this Privacy Policy.</p>
    <p>Use Of Personal Information:</p>
      <p>We use Personal Information for the following purposes:</p>
      <ul>
        <li>- to provide you with information about our Services;</li>
        <li>- for the creation or development of business intelligence or data analytics in relation to the Services provided by us on our Platform (for this purpose, we may share the Personal Information with certain software or tools available online);
      </li>
      <li>- to improve the Services;</li>
      <li>- to address your queries;</li>
      <li>- to run marketing and promotional campaigns;</li>
      <li>- to process job applications (if you apply for a position with our organization);</li>
      <li>- to maintain leads;</li>
      <li>- for administration/billing;</li>
      <li>- to manage our relationship with you;</li>
      <li>- to provide you information that we may believe may be of your interest;</li>
      <li>- for internal record keeping; and</li>
      <li>- to comply with our legal and/or statutory obligations.</li>
      </ul>

    <p>Disclosures:</p>
      <p>
        We do not sell, rent, share, distribute, lease, or otherwise provide your Personal Information to third parties without your prior consent. Keeping this in mind, we may disclose your Personal Information in the following cases:
      </p>

    <ul>
      <li>
        - Affiliates:
        <p>
        We may provide your Personal Information to our affiliates to enable them to improve the Platform, provide feedback, and respond to their queries.
        </p>
      </li>
      <li>
        - Serivice Provider
        <p>
        We may share your Personal Information with the service providers who work with us in connection with operating the Platform and/or providing the Services. All such service providers are subject to stringent confidentiality restrictions consistent with this Privacy Policy.
        </p>
      </li>
      <li>
        - Merger of Acquitions:
        <p>
        We may transfer your Personal Information if we are acquired by another entity, or if we merge with another company or transfer a part of our business, including the Application, to a third party. Any such third party or resultant entity that receives your Personal Information shall have the right to continue to use your Personal Information in line with the purposes set out herein. In the event of such a sale or transfer, we may notify you.
        </p>
      </li>
      <li>
        - Legal and Regulatory Authorities:
        <p>
        We may disclose your Personal Information to comply with our legal obligations/court orders/requests by Govt. authorities.
        </p>
      </li>
    </ul>

    <p>Data Retention:</p>
    <p>
    We will retain User’s Personal Information as long as it is required to be retained for the purpose of the provision of the Services. We may also retain and use User’s Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
    </p>

    <p>Security Measures:</p>
    <p>
    Your Personal Information is stored on third-party cloud servers. Although we provide appropriate firewalls and protections, we cannot warrant the security of Personal Information transmitted as these systems are not hack-proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible, and will take necessary measures to mitigate such events. You are required to be careful to avoid “phishing” scams, where someone may send you an e-mail that looks like it is from us asking for your personal information.
    </p>

    <p>Children’s Privacy:</p>
    <p>
    Our Platform is not meant for individuals under the age of eighteen (18) years, and we will not directly collect, use, provide or process in any other form. If you are below the age of eighteen years, kindly refrain from using the Platform. In the event, we are made aware of any collection of Personal Information that belongs to an individual who is below the age of eighteen years, we will delete such Personal Information.
    </p>

    <p>Your Rights:</p>
    <p>
    You have the right to access Personal Information in our possession, the right to have us rectify or modify any such Personal Information, the right to have us erase/delete your Personal Information, the right to restrict us from processing such Personal Information, the right to object to our use of Personal Information, withdraw consent at any time where we are relying on consent to process Personal Information. Depending on the nature of the request, we may ask you to complete a Personal Information request form or seek certain details to verify the request. All requests for Personal Information will be handled within a reasonable period of time. If you would like to exercise ANY of these rights, please [insert process for exercising rights].
    </p>
    <p>Choice And Opt-Out:</p>
    <p>We may send you communications including but not limited to (a) notices about your use of our Platform, including those concerning violations of use, (b) updates, (c) promotional information regarding our Platform, and (d) newsletters. You may opt out of receiving promotional emails and newsletters from us by following the unsubscribe instructions provided in those emails. Alternatively, you can opt out, at any time, by emailing CRYSTALPOLY2017@GMAIL.COM with your specific request.</p>
    
    <p>Links To Other Platforms:</p>
    <p>
    Our Platform may contain links to other websites/applications of your interest. Please note that we do not have any control over such other websites/applications, and you will be accessing these websites/applications at your own risk. Therefore, we cannot be responsible for the protection and privacy of any information which you provide while visiting such websites/applications and those are not governed by this Privacy Policy. You should exercise caution and look at the privacy policy applicable to such websites/applications.
    </p>

    <p>Limitations of Liability:</p>
    <p>
    To the extent permissible under the law, we shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for the loss of profits, goodwill, data, information, or other intangible losses (even if we have been advised of the possibility of such damages), arising out of this Privacy Policy.
    </p>
    
    <p>Governing Laws:</p>
    <p>
    This Privacy Policy shall in all respects be governed by and construed and enforced in accordance with the laws of the Republic of India, and the courts in VADODARA shall have an exclusive jurisdiction to adjudicate any subject matter under this Privacy Policy.
    </p>

    <p>Changes To This Policy:</p>
    <p>Please revisit this page periodically to stay aware of any changes to this Privacy Policy, which we may update from time to time. If we modify this Privacy Policy, we will make it available through the Platform and indicate the date of the latest revision</p>
    
    <p>Contact Us</p>
    <p>If you have any questions or concerns or grievances regarding this Privacy Policy or wish to withdraw your consent in relation to the processing of your Personal Information, you can reach out to our appointed grievance redressal officer Saurabh Limbani via email at crystalpoly2017@gmail.com.</p>
    <p>Home</p>
    <p>CRYSTALPOLY2017@GMAIL.COM</p>
    <p>+91 8000906677</p>
    <p>+91 9913506569</p>
    <p>Survey no: 873/B/3, ANSONS LIMBANI ESTATE, GIDC POR, VADODARA 391243. </p>
    <p>Explore</p>
    <p>Home</p>
    <p>About us</p>
    <p>Buy Polumers</p>
    <p>Sell Polymers</p>
    <p>Careers</p>
    <p>Media</p>
    <p>Resources</p>
    <p>Contact</p>
    <p>Terms of Use</p>
    <p>Privacy Policy</p>
    <p>Follow Us</p>
    <p>Facebook-square Linkedin Instagram</p>
    <p>Sell Polymers Online</p>
    <footer>
        <p>&copy; 2023 CRYSTAL POLYMERS | Powered by LIMBANI SOFTWARES</p>
    </footer>
    </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </div>
    )
}
